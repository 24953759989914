@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	h1 {
		font-size:5vw;
		color:#0033FF;
		padding:0;
		margin:0;

		@include md {
			font-size:20px;
		}
	}

	h2 {
		font-size:3vw;
		color:#000;
		padding:0;
		margin:0;

		@include md {
			font-size:20px;
		}

	}

	table {
		float:left;
		width:100%;
		color:#000;
		border-collapse:collapse;

		tr {
			border-bottom:1px solid #ccc;

			td {
				padding:10px;
			}
		}

	}

	ul  {
		margin:0;
		padding:0;
	}

	ul li {
		// list-style: none;
		// margin:0;
		// padding:0;
	}

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size: 1.5vw;
		line-height:2.5vw;
		color:#000;

		@include md {
			font-size:16px;
			line-height:25px;
		}

		a {
			color:blue;
		}

	}

}