@import "_default.scss_include-mixins";	 

header {

	position:fixed;
	top:0;
	left:0;
	width:30vw;
	height:100vh;
	overflow:hidden;
	padding-left:2vw;
	padding-right:2vw;

	@include md {
		position:relative;
		float:left;
		width:100%;
		height:auto;
		margin-top:20px;
		overflow:auto;
		z-index:100;
	}

	.inside {
		position:absolute;
		top:50%;
		width:100%;
		transform:translate(0,-50%);

		@include md {
			position:relative;
			top:auto;
			transform:translate(0,0);
		}

		
		h2 {
			margin-bottom:20px !important;			
		}

		nav {

			&.line {
				@include md {
					float:left;
					width:100%;
					padding-bottom:20px;
					border-bottom:2px solid #000;
				}
			}

			a {
				float:left;
				font-size:1.5vw;
				color:#000;
				padding:0;
				margin:0;
				margin-bottom:0.8vw;
				margin-right:1vw;
				font-weight:bold;
				color:#000;
				text-decoration: none;

				@include md {
					font-size:15px;
					line-height:20px;
					margin-right:20px;
				}

				&:hover,
				&.active {
					color:#0033FF;
				}
			}

		}

		.contact {

			float:left;
			width:100%;
			margin-top:1vw; 

			h2 {
				float:left;
				width:100%;

				.title {
					position:relative;
					float:left;

					.info {
						position:absolute;
						top:-30px;
						right:-30px;
						cursor:pointer;

						@include sm {
							top:-10px;
							right:-25px;
						}

						svg {
							width:30px;
							height:30px;

							@include sm {
								width:20px;
								height:20px;
							}
						}				
					}

				}


			}

			@include md {
				margin-top:20px;

				padding-bottom:20px;
				border-bottom:2px solid #000;
			}

			.left {
				float:left;
				width:15%;

				.face {
					float:left;
					width:100%;
					padding-top:100%;
					background-image:url('../img/static/immits-face.jpg');
					background-repeat:no-repeat;
					background-size:contain;
				}

			}

			.right {
				float:left;
				width:85%;
				margin-top:0.2vw;

				a {
					clear:both;
					float:left;
					color:#0033FF;

					&:hover {
						color:#000;
					}
				}

			}

		}

	}

}