@import "_default.scss_include-mixins";

.color-white {
	color:#fff !important;
}

.color-black {
	color:#000 !important;
}

.color-rose {
	color:#d99593 !important;
}