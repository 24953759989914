@import "_default.scss_include-mixins";

.popup--default {

	position:relative;
	float:left;
	width::55vw;

	@include md {
		width:90vw;
	}

	.content {
		float:left;
		width:100%;
		color:#000;
		background-color:#fff;
		padding:40px;
		padding-bottom:30px;
		padding-top:30px;
		border-radius:15px;
		text-align:center;

		@include md {
			padding:20px;
		}

		h2 {
			margin-top:1vw;
			margin-bottom:1vw;
			font-size:2.5vw;

			@include md {
				font-size:20px;
				margin-top:10vw;
			}
		}

		p {
			margin-top:2vw;
			padding-bottom:1vw;
		}

		.form {
			padding-left:3vw;
			padding-right:3vw;

			input,
			select {
				font-size:1vw;
				padding:1vw;
				border-radius:50px;

				@include md {
					font-size:15px;
					padding:10px;
					padding-left:20px;
					padding-right:20px;
				}
			}

			.element-row {

				margin-top:20px;

				@include sm {
					margin-top:10px;
				}
			}

		}

	}

}