@import "_default.scss_include-mixins";

.button-1 {

	display:inline-block;

	padding:10px;
	padding-left:20px;
	padding-right:20px;
	background-color:#fff;
	box-shadow:0 10px 10px rgba(0,0,0,0.2);
	cursor:pointer;

	transition:background-color 0.3s;

	&.cta {
		background-color:green;
		.inside {
			.label {
				color:#fff;
			}
		}
	}

	.inside {

		float:left;
		width:100%;

		.label {
			float:left;
			width:100%;
			text-align:center;
			font-family:'Open Sans';
			font-size:1vw;
			color:#000;
			font-weight:700;
			text-decoration: underline;

			@include md {
				font-size:14px;
			}
		}

	}

	&:hover {
		background-color:#FF6400;

		.label {
			color:#fff;
		}
	}

};


.button-2 {

	float:none;
	display:inline-block;

	padding:5px;
	padding-left:20px;
	padding-right:20px;
	cursor:pointer;
	background-color:#E1E1E1;
	border-radius:100px;

	.inside {

		float:left;
		width:100%;

		.icon {
			float:left;
			margin-right:10px;
			margin-top:5px;

			svg {
				width:20px;
				height:20px;
				fill:#000;
			}
		}

		.label {
			float:left;
			margin-top:3px;
			text-align:center;
			font-family:'Open Sans';
			font-size:0.8vw;
			color:#000;
			font-weight:normal;

			@include md {
				font-size:14px;
			}

		}

	}

	&:hover,
	&.active {
		opacity:1;
	}

};