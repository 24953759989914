@import "_default.scss_include-mixins";

body {
	font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
	background-color:#fff;
	overflow:hidden;

	@include md {
		overflow:auto;
	}
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}
