@import "_default.scss_include-mixins";

.popup--info {
	float:left;
	width::1000px;

	@include sm {
		width:280px;
	}

	&.bigger {
		width:900px;

		@include sm {
			width:280px;
		}
	}

	&.small {
		width:500px;

		@include sm {
			width:280px;
		}
	}

	.content {
		float:left;
		width:100%;
		color:#000;
		background-color:#fff;
		padding:40px;
		padding-bottom:30px;
		padding-top:30px;
		border-radius:15px;
		text-align:left;

		.img-maxwidth {
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);

			@include sm {
				position:relative;
				top:auto;
				left:auto;
				transform:none;
			}
		}

		@include sm {
			padding:20px;
		}

	}

}
	