@import "_default.scss_include-mixins";

.page.content--project {

	position:fixed;
	top:0;
	left:30vw;
	width:70vw;
	height:100vh;
	overflow:auto;
	padding-left:2vw;
	padding-right:2vw;

	background-color:#fff;

	@include md {
		position:relative;
		left:0;
		width:100%;
		height:auto;
		margin-top:20px;
	}

	.content {
		float:left;
		width:100%;
	}

	.block {
		float:left;
		width:100%;
		margin-bottom:3vw;

		@include md {
			margin-bottom:20px;
		}

		&.fb {
			margin-top:3vw;
		}

		video {
			width:100%;
		}

		img {
			max-width:100%;
		}

	}

	
}