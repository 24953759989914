@import "_default.scss_include-mixins";

.page.content--home {

	position:fixed;
	top:0;
	left:30vw;
	width:70vw;
	height:100vh;
	overflow:hidden;

	background-color:#fff;

	@include md {
		left:0;
		width:100vw;
	}

	.textblock-1 h1 {
		
		span {
			
			opacity:0.1;

			@include md {
				opacity:0.05
			}

			&.lighted {
				color:#ed4409;
				opacity:0.2;
			}

		}
	}

	.marquee-wrapper {

		position:absolute;
	  	// animation: marqueeVertical 120s linear infinite;

	}

}